<template>
  <div
    v-if="data"
    class="page has-background-blue with-title"
  >
    <h1 class="page-title has-text-weight-bold has-text-centered is-size-5">
      {{ section_title }}
    </h1>
    <b-tabs
      class="mt-2 tasks-tabs"
      type="is-toggle"
      position="is-centered"
    >
      <b-tab-item label="Actuales">
        <div class="container p-4">
          <div class="columns is-multiline is-mobile">
            <generic-list-item
              v-for="item in currentTasks"
              :key="item.id"
              :data="item"
              @click="openContent(item)"
            >
              <template #image>
                <img
                  class="disc"
                  :src="getImagePath(item.image)"
                  :alt="item.title"
                >
              </template>
            </generic-list-item>
            <div
              v-if="emptyCurrent"
              class="column is-12-mobile is-6-tablet is-offset-3-tablet"
            >
              <div class="no-results">
                <p class="mb-2">
                  No se han encontrado tareas a realizar.
                </p>
                <p class="has-text-weight-bold">
                  ¡Felicidades!
                </p>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Pasadas">
        <div class="container p-4">
          <div class="columns is-multiline is-mobile">
            <generic-list-item
              v-for="item in expiredTasks"
              :key="item.id"
              :data="item"
              @click="openContent(item)"
            >
              <template #image>
                <img
                  class="disc"
                  :src="getImagePath(item.image)"
                  :alt="item.title"
                >
              </template>
            </generic-list-item>
            <div
              v-if="emptyExpired"
              class="column is-12-mobile is-6-tablet is-offset-3-tablet"
            >
              <div class="no-results">
                <p>No se han encontrado tareas pasadas.</p>
                <p>Recuerda que sólo verás las tareas de los últimos 3 meses.</p>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import GenericListItem from "@/components/lists/GenericListItem";
import ImageUtils from "@/utils/image";

export default {
  name: 'TaskList',
  components: {GenericListItem},
  title: 'Tarea semanal',
  data: function () {
    return {
      section_title: 'Tarea semanal',
      data: null
    }
  },
  computed: {
    emptyCurrent() {
      if (!this.data) {
        return true
      }

      return this.currentTasks.length === 0
    },
    emptyExpired() {
      if (!this.data) {
        return true
      }

      return this.expiredTasks.length === 0
    },
    userToken() {
      return this.$store.state.token
    },
    currentTasks(){
      return this.data.filter((item => item.expired === false))
    },
    expiredTasks(){
      return this.data.filter((item => item.expired === true))
    }
  },
  watch: {
    userToken() {
      this.loadContent()
    }
  },
  beforeMount() {
    this.loadContent()
  },
  methods: {
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')

        this.data = await this.$api.task.getList()

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    openContent(item) {
      this.$router.push({name: 'tasks-detail', params: {slug: item.slug}})
    },
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
  }
}
</script>

<style scoped lang="scss">
.no-results{
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

.tasks-tabs .tabs {
  background-color: white;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
