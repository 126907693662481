<template>
  <div v-if="data" class="page has-background-blue with-title">
    <div class="columns is-multiline is-mobile">
      <div class="column pb-0 is-gapless is-12">
        <div class="gapless">
          <img :src="getImagePath(data.image)" :alt="data.title" width="100%"/>
        </div>
      </div>
      <div class="column is-12 has-background-white">
        <div class="main-title px-5">
          <h4 class="subtitle mb-1">{{ data.title }}</h4>
          <div class="is-size-7 mb-3 has-text-weight-semibold has-text-primary">
            <div>
              <b-icon icon="calendar"></b-icon>
              <span>Del {{ data.start_at | prettyDate }} al {{ data.end_at | prettyDate }}</span>
            </div>
            <div>
              <b-icon icon="folder"></b-icon>
              <span class="group" v-for="group in data.groups" :key="group.id">{{ group.title}} ({{group.method}})</span>
            </div>
          </div>
        </div>
        <div class="content px-5">
          <div v-html="data.content" class="has-text-grey-dark"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtils from "@/utils/image";

export default {
  title: 'Noticia',
  name: 'TaskDetail',
  data: function () {
    return {
      section_title: 'Tarea semanal',
      data: null
    }
  },
  computed: {
    emptyResult() {
      return this.school === null
    },
    userToken() {
      return this.$store.state.token
    }
  },
  methods: {
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')
        const slug = this.$route.params.slug
        this.data = await this.$api.task.getItemBySlug(slug)

        this.setPageTitle(this.data.title)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    }
  },
  beforeMount() {
    this.loadContent()
  },
  watch: {
    userToken() {
      this.loadContent()
    }
  }
}
</script>
