<template>
  <div
    class="list-item separator has-background-white column is-full py-2 px-5"
    @click="$emit('click',data)"
  >
    <div class="columns is-mobile is-vcentered">
      <div class="column is-3">
        <slot name="image">
          <img
            src="@/assets/img/article-list-icon.png"
            class="mt-1"
            alt=""
          >
        </slot>
      </div>
      <div class="column is-7 is-size-7">
        <h4 class="has-text-weight-bold">
          {{ data.title }}
        </h4>
        <p class="has-text-primary">
          {{ data.start_at | prettyDate }}
          <template v-if="showEnd">
            {{ data.end_at | prettyDate }}
          </template>
        </p>
      </div>
      <div class="column is-2">
        <b-icon icon="angle-right" />
      </div>
    </div>
    <div
      v-if="hasLevel"
      class="level-item"
      :class="levelClass"
    />
  </div>
</template>

<script>
import ImageUtils from '@/utils/image'

export default {
  name: "GenericListItem",
  props: {
    data: {
      type: Object,
      default: null
    },
    showEnd: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isValid: true,
      LEVELS: {
        'rojo': 'red-bg',
        'naranja': 'orange-bg',
        'azul': 'blue-bg',
        'verde': 'green-bg'
      }
    }
  },
  computed: {
    levelClass() {
      if (!this.data ||
        !this.data.hasOwnProperty('level') ||
        !this.LEVELS.hasOwnProperty(this.data.level)
      ) {
        return ''
      }

      return this.LEVELS[this.data.level]
    },
    hasLevel() {
      return this.levelClass !== ''
    }
  },
  methods: {
    getImagePath(book) {
      const src = book.cover === '' ? book.cover_alt : book.cover

      return ImageUtils.getCDNFullPath(src)
    },
    imageError() {
      console.error("Error cargando imagen, ocultando interactivo")
      this.isValid = false;
    }
  }
}
</script>
